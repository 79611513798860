import { Link } from "@remix-run/react";
import React from "react";
import { RemixLinkProps } from "@remix-run/react/dist/components";
import { classNames } from "~/lib";

function StyledLink({
  to,
  prefetch,
  className,
  children,
  title,
}: RemixLinkProps) {
  return (
    <Link
      to={to}
      title={title}
      prefetch={prefetch}
      className={classNames(
        "text-[#337ab7] transition-all duration-200 hover:text-[#23527c]",
        className ?? ""
      )}
    >
      {children}
    </Link>
  );
}

export default StyledLink;
